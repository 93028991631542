import '/assets/styles/layouts/base.scss';
import PropTypes from 'prop-types';

// Sections
import Header from '/views/sections/header'
import Footer from '/views/sections/footer'

const BaseLayout = ({ children, pageClass }) => {
	return (
		<div className={`page layout-base ${pageClass ? 'page-' + pageClass : ''}`}>
			<Header />
			<div className="layout-base-content">
				{children}
			</div>
			<Footer />
		</div>
	)
}

BaseLayout.propTypes = {
	children: PropTypes.node,
	pageClass: PropTypes.string,
}

export default BaseLayout